import { navigate } from "gatsby"
import React, { useContext, useEffect } from "react"
import { AuthContext } from "../../../context/Auth"
import { setAccessToken, validatePythonToken } from "../../../utils/request"

export default function JwtLogin(props) {
  const { jwt, accessToken } = props.params

  const { setTokenAndUser } = useContext(AuthContext)

  useEffect(() => {
    ;(async function () {
      const isTokenValid = await validatePythonToken(jwt)

      if (isTokenValid) {
        setTokenAndUser(jwt, accessToken, {
          name: "Admin",
          email: "admin@skillstrainer.in",
        })
      } else {
        setTokenAndUser(false, false, false)
        alert("The token is invalid")
      }

      navigate("/")
    })()
  }, [])

  return null
}
